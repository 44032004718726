<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">Feather Icons</h2>
  </div>
  <!-- BEGIN: Icon List -->
  <div class="intro-y grid grid-cols-12 sm:gap-6 row-gap-6 box px-5 py-8 mt-5">
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ActivityIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ActivityIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AirplayIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AirplayIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlertCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlertCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlertOctagonIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlertOctagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlertTriangleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlertTriangleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlignCenterIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlignCenterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlignJustifyIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlignJustifyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlignLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlignLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AlignRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AlignRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AnchorIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AnchorIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ApertureIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ApertureIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArchiveIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArchiveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowDownCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowDownLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowDownRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowLeftCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowLeftCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowRightCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowRightCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowUpCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowUpLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowUpRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ArrowUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AtSignIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AtSignIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <AwardIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">AwardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BarChart2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">BarChart2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BarChartIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BarChartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BatteryChargingIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BatteryChargingIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BatteryIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BatteryIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BellOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BellOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BellIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BellIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BluetoothIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BluetoothIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BoldIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BoldIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BookOpenIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BookOpenIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BookIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BookIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BookmarkIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BookmarkIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BoxIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BoxIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <BriefcaseIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">BriefcaseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CalendarIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CalendarIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CameraOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CameraOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CameraIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CameraIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CastIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CastIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CheckCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CheckCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CheckSquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CheckSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CheckIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CheckIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronsDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronsLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronsRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChevronsUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ChromeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ChromeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ClipboardIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ClipboardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ClockIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ClockIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CloudDrizzleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CloudDrizzleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CloudLightningIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CloudLightningIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CloudOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CloudOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CloudRainIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CloudRainIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CloudSnowIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CloudSnowIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CloudIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CloudIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CodeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CodeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CodepenIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CodepenIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CodesandboxIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CodesandboxIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CoffeeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CoffeeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ColumnsIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ColumnsIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CommandIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CommandIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CompassIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CompassIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CopyIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CopyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerDownLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerDownLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerDownRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerDownRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerLeftDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerLeftDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerLeftUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerLeftUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerRightDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerRightDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerRightUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerRightUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerUpLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerUpLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CornerUpRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CornerUpRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CpuIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CpuIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CreditCardIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CreditCardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CropIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CropIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <CrosshairIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">CrosshairIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DatabaseIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DatabaseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DeleteIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DeleteIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DiscIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DiscIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DivideCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DivideCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DivideSquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DivideSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DivideIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DivideIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DollarSignIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DollarSignIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DownloadCloudIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DownloadCloudIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DownloadIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DownloadIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DribbbleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DribbbleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <DropletIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">DropletIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Edit2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Edit2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Edit3Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Edit3Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <EditIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">EditIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ExternalLinkIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ExternalLinkIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <EyeOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">EyeOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <EyeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">EyeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FacebookIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FacebookIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FastForwardIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FastForwardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FeatherIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FeatherIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FigmaIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FigmaIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FileMinusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FileMinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FilePlusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FilePlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FileTextIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FileTextIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FileIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FileIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FilmIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FilmIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FilterIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FilterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FlagIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FlagIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FolderMinusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FolderMinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FolderPlusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FolderPlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FolderIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FolderIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FramerIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FramerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <FrownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">FrownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GiftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GiftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GitBranchIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GitBranchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GitCommitIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GitCommitIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GitMergeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GitMergeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GitPullRequestIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GitPullRequestIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GithubIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GithubIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GitlabIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GitlabIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GlobeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GlobeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <GridIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">GridIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HardDriveIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HardDriveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HashIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HashIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HeadphonesIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HeadphonesIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HeartIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HeartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HelpCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HelpCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HexagonIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HexagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <HomeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">HomeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ImageIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ImageIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <InboxIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">InboxIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <InfoIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">InfoIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <InstagramIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">InstagramIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ItalicIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ItalicIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <KeyIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">KeyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LayersIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LayersIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LayoutIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LayoutIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LifeBuoyIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LifeBuoyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Link2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Link2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LinkIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LinkIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LinkedinIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LinkedinIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ListIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ListIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LoaderIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LoaderIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LockIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LockIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LogInIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LogInIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <LogOutIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">LogOutIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MailIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MailIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MapPinIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MapPinIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MapIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MapIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Maximize2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Maximize2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MaximizeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MaximizeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MehIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MehIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MenuIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MenuIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MessageCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MessageCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MessageSquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MessageSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MicOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MicOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MicIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MicIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Minimize2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Minimize2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MinimizeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MinimizeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MinusCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MinusCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MinusSquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MinusSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MinusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MonitorIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MonitorIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MoonIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MoonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MoreHorizontalIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MoreHorizontalIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MoreVerticalIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MoreVerticalIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MousePointerIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MousePointerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MoveIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MoveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <MusicIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">MusicIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Navigation2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Navigation2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <NavigationIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">NavigationIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <OctagonIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">OctagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PackageIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PackageIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PaperclipIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PaperclipIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PauseCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PauseCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PauseIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PauseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PenToolIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PenToolIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PercentIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PercentIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneCallIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneCallIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneForwardedIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneForwardedIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneIncomingIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneIncomingIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneMissedIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneMissedIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneOutgoingIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneOutgoingIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PhoneIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PhoneIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PieChartIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PieChartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PlayCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PlayCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PlayIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PlayIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PlusCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PlusCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PlusSquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PlusSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PlusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PocketIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PocketIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PowerIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PowerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <PrinterIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">PrinterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RadioIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RadioIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RefreshCcwIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RefreshCcwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RefreshCwIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RefreshCwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RepeatIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RepeatIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RewindIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RewindIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RotateCcwIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RotateCcwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RotateCwIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RotateCwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <RssIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">RssIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SaveIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SaveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ScissorsIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ScissorsIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SearchIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SearchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SendIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SendIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ServerIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ServerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SettingsIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SettingsIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Share2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Share2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ShareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ShareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ShieldOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ShieldOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ShieldIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ShieldIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ShoppingBagIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ShoppingBagIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ShoppingCartIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ShoppingCartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ShuffleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ShuffleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SidebarIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SidebarIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SkipBackIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SkipBackIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SkipForwardIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SkipForwardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SlackIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SlackIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SlashIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SlashIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SlidersIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SlidersIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SmartphoneIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SmartphoneIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SmileIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SmileIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SpeakerIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SpeakerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <StarIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">StarIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <StopCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">StopCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SunIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SunIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SunriseIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SunriseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <SunsetIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">SunsetIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TabletIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TabletIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TagIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TagIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TargetIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TargetIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TerminalIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TerminalIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ThermometerIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ThermometerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ThumbsDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ThumbsDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ThumbsUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ThumbsUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ToggleLeftIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ToggleLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ToggleRightIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ToggleRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ToolIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ToolIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Trash2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Trash2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TrashIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TrashIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TrelloIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TrelloIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TrendingDownIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TrendingDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TrendingUpIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TrendingUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TriangleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TriangleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TruckIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TruckIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TvIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TvIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TwitchIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TwitchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TwitterIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TwitterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <TypeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">TypeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UmbrellaIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UmbrellaIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UnderlineIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UnderlineIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UnlockIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UnlockIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UploadCloudIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UploadCloudIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UploadIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UploadIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UserCheckIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UserCheckIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UserMinusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UserMinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UserPlusIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UserPlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UserXIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UserXIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UserIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UserIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <UsersIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">UsersIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <VideoOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">VideoOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <VideoIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">VideoIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <VoicemailIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">VoicemailIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Volume1Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Volume1Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <Volume2Icon class="mx-auto" />
      <div class="text-center text-xs mt-2">Volume2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <VolumeXIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">VolumeXIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <VolumeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">VolumeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <WatchIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">WatchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <WifiOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">WifiOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <WifiIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">WifiIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <WindIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">WindIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <XCircleIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">XCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <XOctagonIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">XOctagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <XSquareIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">XSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <XIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">XIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <YoutubeIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">YoutubeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ZapOffIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ZapOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ZapIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ZapIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ZoomInIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ZoomInIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
      <ZoomOutIcon class="mx-auto" />
      <div class="text-center text-xs mt-2">ZoomOutIcon</div>
    </div>
  </div>
  <!-- END: Icon List -->
</template>
